import {
  Tooltip,
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Toolbar,
  Typography,
  Paper,
  Fade,
  useMediaQuery,
} from "@mui/material";
import {
  Brightness4 as Brightness4Icon,
  Brightness7 as Brightness7Icon,
  ExitToApp as ExitToAppIcon,
  Home as HomeIcon,
  Notifications as NotificationsIcon,
  Person as PersonIcon,
  Settings as SettingsIcon,
  ShoppingCart as ShoppingCartIcon,
} from "@mui/icons-material";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { onAuthStateChanged, signOut } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { auth } from "../../config/firebaseConfig";
import EmailNotVerified from "../auth/EmailNotVerified";
import { fetchUserData, listenForNotifications } from "./Firebase_Manager/Data_Manager";
import Home from "./Home/Home";
import Settings from "./Settings/Settings";
import { AppBarStyled, TabStyled, TabsStyled, getTheme } from "./Styles/User_Dashboard_Styles";
import UserOrders from "./orders/UserOrders";
import UserProfile from "./user/UserProfile";
import { t } from "i18next";
import SEO from "../../services/seo";

const UserDashboard = ({ UserClient }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [mode, setMode] = useState("light");
  const [notificationDialogOpen, setNotificationDialogOpen] = useState(false);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        UserClient = user;

        const userDataFetched = await fetchUserData(user.uid);
        setUserData(userDataFetched);

        const unsubscribeNotifications = listenForNotifications(user.uid, (newNotifications) => {
          setNotifications(newNotifications);
          setUnreadNotifications(newNotifications.filter((n) => !n.read).length);
        });

        return () => unsubscribeNotifications();
      } else {
        setCurrentUser(null);
        setNotifications([]);
        setUnreadNotifications(0);
      }
    });

    return () => unsubscribeAuth();
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleLogoutClick = () => setLogoutDialogOpen(true);

  const handleLogoutConfirm = () => {
    signOut(auth)
      .then(() => setLogoutDialogOpen(false))
      .catch((error) => console.error("Error logging out:", error));
  };

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  const handleNotificationClick = () => setNotificationDialogOpen(true);
  const handleNotificationDialogClose = () => setNotificationDialogOpen(false);

  const renderContent = () => {
    switch (selectedTab) {
      case 0:
        return <Home currentUserUid={currentUser?.uid} />;
      case 1:
        return <UserProfile user={currentUser} userData={userData} />;
      case 2:
        return <UserOrders customerUid={currentUser?.uid} />;
      case 3:
        return <Settings user={currentUser} userData={userData} />;
      default:
        return <Home currentUserUid={currentUser?.uid} />;
    }
  };

  const tabItems = [
    { label: t("home"), icon: <HomeIcon />, index: 0 },
    { label: t("my_profile"), icon: <PersonIcon />, index: 1 },
    { label: t("my_orders"), icon: <ShoppingCartIcon />, index: 2 },
    { label: t("settings"), icon: <SettingsIcon />, index: 3 },
  ];

  if (!UserClient.emailVerified) {
    return <EmailNotVerified />;
  }

  return (
    <>
      <SEO
        title={t("client_dashboard")}
        description="Effortlessly manage all your orders, track your expenses, and more in one convenient location."
        url="https://services.aminejameli.com/me"
        image="https://services.aminejameli.com/images/amine-jameli.png"
      />
    <ThemeProvider theme={getTheme(mode)}>
      <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
        
        <CssBaseline />
        <AppBarStyled position="static" elevation={4}>
          <Toolbar sx={{ 
            justifyContent: "space-between", 
            alignItems: "center",
            padding: { xs: '0.5rem 1rem', sm: '1rem 2rem' }
          }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Avatar
                src={userData?.profilePicture || "/images/default-avatar.png"}
                alt={userData?.fullName || t("guest")}
                sx={{ 
                  width: { xs: 35, sm: 40, md: 45 }, 
                  height: { xs: 35, sm: 40, md: 45 },
                  boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
                }}
              />
              <Fade in={!isMobile}>
                <Typography 
                  variant={isTablet ? "h6" : "h5"} 
                  component="div"
                  sx={{ 
                    fontWeight: 600,
                    display: { xs: 'none', sm: 'block' }
                  }}
                >
                  {t("hello") + ", " + (userData?.fullName || t("guest"))}
                </Typography>
              </Fade>
            </Box>

            <Box sx={{ display: "flex", gap: { xs: 1, sm: 2 } }}>
              <Tooltip title={t("notifications")} arrow>
                <IconButton 
                  color="inherit" 
                  onClick={handleNotificationClick}
                  sx={{ '&:hover': { transform: 'scale(1.1)' } }}
                >
                  <Badge 
                    badgeContent={unreadNotifications} 
                    color="error"
                    sx={{ '& .MuiBadge-badge': { fontSize: '0.7rem' } }}
                  >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Tooltip title={mode === "dark" ? t("light_mode") : t("dark_mode")} arrow>
                <IconButton 
                  onClick={toggleTheme} 
                  color="inherit"
                  sx={{ '&:hover': { transform: 'scale(1.1)' } }}
                >
                  {mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
              </Tooltip>
              <Tooltip title={t("logout")} arrow>
                <IconButton 
                  color="inherit" 
                  onClick={handleLogoutClick}
                  sx={{ '&:hover': { transform: 'scale(1.1)' } }}
                >
                  <ExitToAppIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
          <Paper elevation={4} sx={{ borderRadius: 0 }}>
            <TabsStyled
              value={selectedTab}
              onChange={handleTabChange}
              variant={isMobile ? "scrollable" : "fullWidth"}
              scrollButtons={isMobile ? "auto" : false}
              centered={!isMobile}
              sx={{
                '& .MuiTabs-indicator': {
                  height: 3,
                  borderRadius: '3px 3px 0 0'
                }
              }}
            >
              {tabItems.map((item) => (
                <TabStyled
                  key={item.index}
                  icon={item.icon}
                  label={!isMobile && item.label}
                  aria-label={item.label}
                  iconPosition="top"
                  sx={{
                    minHeight: { xs: '48px', sm: '64px' },
                    fontSize: { xs: '0.75rem', sm: '0.875rem' }
                  }}
                />
              ))}
            </TabsStyled>
          </Paper>
        </AppBarStyled>

        <Dialog
          open={notificationDialogOpen}
          onClose={handleNotificationDialogClose}
          PaperProps={{ 
            style: { 
              borderRadius: 16,
              padding: '8px'
            } 
          }}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle sx={{ pb: 1 }}>{t("notifications")}</DialogTitle>
          <DialogContent sx={{ pt: '8px !important' }}>
            {notifications.length > 0 ? (
              notifications.map((notification) => (
                <Paper
                  key={notification.id} 
                  elevation={1}
                  sx={{ 
                    p: 2, 
                    mb: 2,
                    borderRadius: 2,
                    backgroundColor: notification.read ? 'background.paper' : 'action.hover'
                  }}
                >
                  <Typography variant="body1" sx={{ mb: 1 }}>{notification.Content}</Typography>
                  <Typography variant="caption" color="text.secondary">
                    {new Date(notification.Notifications_Date.seconds * 1000).toLocaleString()}
                  </Typography>
                </Paper>
              ))
            ) : (
              <Typography variant="body2" sx={{ textAlign: 'center', py: 3 }}>
                {t("no_notifications")}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={handleNotificationDialogClose}
              variant="contained"
              sx={{ borderRadius: 2 }}
            >
              {t("close")}
            </Button>
          </DialogActions>
        </Dialog>

        <Container 
          component="main" 
          sx={{ 
            flexGrow: 1, 
            py: { xs: 2, sm: 3, md: 4 },
            px: { xs: 1, sm: 2, md: 3 }
          }}
        >
          <Fade in={true} timeout={500}>
            <Box>{renderContent()}</Box>
          </Fade>
        </Container>

        <Dialog
          open={logoutDialogOpen}
          onClose={() => setLogoutDialogOpen(false)}
          PaperProps={{ 
            style: { 
              borderRadius: 16,
              padding: '8px'
            } 
          }}
        >
          <DialogTitle sx={{ pb: 1 }}>{t("confirm_sign_out")}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t("sign_out_confirmation")}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={() => setLogoutDialogOpen(false)}
              variant="outlined"
              sx={{ borderRadius: 2 }}
            >
              {t("cancel")}
            </Button>
            <Button 
              onClick={handleLogoutConfirm} 
              autoFocus 
              color="error"
              variant="contained"
              sx={{ borderRadius: 2 }}
            >
              {t("yes")}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>
    </>
  );
};

export default UserDashboard;
