import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import { signOut } from 'firebase/auth';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../config/firebaseConfig';
import CalendlyWidget from './Schedule_Meeting/Calendly_script';

const Header = ({ user }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userMenuAnchor, setUserMenuAnchor] = useState(null);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuOpen = (event) => {
    setUserMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchor(null);
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate('/');
      handleUserMenuClose();
    });
  };
 

  return (
    <AppBar 
      position="fixed"
      sx={{
        backgroundColor: 'rgba(248, 132, 43, 0.95)', 
        backdropFilter: 'blur(10px)',
        boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
        margin: { xs: '10px', sm: '20px' }, // Responsive margin
        width: { xs: 'calc(100% - 20px)', sm: 'calc(100% - 40px)' }, // Responsive width
        height: { xs: '80px', sm: '80px' }, // Responsive height
        padding: { xs: '10px', sm: '0px' }, // Responsive padding
        borderRadius: '24px',
        transform: 'translateZ(0)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
    >
      <Toolbar 
        sx={{ 
          minHeight: { xs: '60px', sm: '80px' }, // Responsive minHeight
          px: { xs: 1, sm: 2, md: 4 }, // Responsive padding
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <IconButton 
          edge="start"
          color="inherit"
          aria-label={t('menu')}
          onClick={handleMenuOpen}
          sx={{ 
            mr: 2,
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
              transform: 'scale(1.05)',
            },
            transition: 'all 0.3s ease',
          }}
        >
          <MenuIcon />
        </IconButton>
  
        <Box 
          sx={{ 
            flexGrow: 1, 
            display: 'flex', 
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <a href='/' style={{ textDecoration: 'none' }}>
            <img 
              src="images/amine_jameli.png"
              alt={t('logo_alt_text')}
              style={{ 
                height: '70px',
                width: '70px', 
                objectFit: 'contain',
                transition: 'all 0.3s ease',
                cursor: 'pointer',
                padding: '8px',
                borderRadius: '50%',
                backgroundColor: 'rgba(255, 255, 255, 0.15)',
                boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.transform = 'scale(1.1) rotate(5deg)';
                e.currentTarget.style.boxShadow = '0 8px 24px rgba(0,0,0,0.15)';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.transform = 'scale(1) rotate(0deg)';
                e.currentTarget.style.boxShadow = '0 4px 12px rgba(0,0,0,0.1)';
              }}
            />
          </a>
        </Box>
  
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {user ? (
            <>
              <IconButton
                color="inherit"
                aria-label={t('user_menu')}
                onClick={handleUserMenuOpen}
                sx={{
                  padding: '12px',
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    transform: 'translateY(-2px)',
                  },
                  transition: 'all 0.3s ease',
                }}
              >
                <AccountCircle sx={{ fontSize: 28 }} />
              </IconButton>
              <Menu
                anchorEl={userMenuAnchor}
                open={Boolean(userMenuAnchor)}
                onClose={handleUserMenuClose}
                PaperProps={{
                  elevation: 3,
                  sx: {
                    backgroundColor: '#fff',
                    borderRadius: '16px',
                    mt: 2,
                    boxShadow: '0 8px 32px rgba(0,0,0,0.12)',
                    '& .MuiMenuItem-root': {
                      padding: '12px 24px',
                      borderRadius: '8px',
                      margin: '4px 8px',
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        backgroundColor: '#fff4e6',
                        color: '#f8842b',
                        transform: 'translateX(4px)',
                      }
                    }
                  }
                }}
              >
                <MenuItem 
                  onClick={() => {
                    navigate('/me');
                    handleUserMenuClose();
                  }}
                  alt={t('my_profile')}
                >
                  {t('my_profile')}
                </MenuItem>
                <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
              </Menu>
            </>
          ) : (
            <Button 
              color="inherit" 
              href="/me"
              alt={t('signup')}
              sx={{
                borderRadius: '30px',
                border: '2px solid rgba(255,255,255,0.8)',
                padding: '10px 28px',
                fontSize: '1rem',
                fontWeight: 500,
                textTransform: 'none',
                transition: 'all 0.3s ease',
                backdropFilter: 'blur(4px)',
                '&:hover': {
                  backgroundColor: 'white',
                  color: '#f8842b',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 8px 20px rgba(0,0,0,0.12)',
                }
              }}
            >
              {t('signup')}
            </Button>
          )}
        </Box>
  
        {/* Main menu styling */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            elevation: 3,
            sx: {
              backgroundColor: '#fff',
              color: '#000',
              boxShadow: '0 8px 32px rgba(0,0,0,0.12)',
              borderRadius: '20px',
              mt: 2,
              padding: '12px',
              '& .MuiList-root': {
                padding: '8px',
              }
            },
          }}
        >
          {/* Menu items with enhanced hover effects */}
          <MenuItem 
            onClick={handleMenuClose}
            component="a"
            href="/"
            sx={{ 
              borderRadius: '12px', 
              margin: '4px',
              padding: '12px 24px',
              transition: 'all 0.2s ease',
              '&:hover': { 
                backgroundColor: '#fff4e6',
                color: '#f8842b',
                transform: 'translateX(4px)',
              } 
            }}
          >
            {t('home')}
          </MenuItem>
          <MenuItem
            onClick={handleMenuClose}
            component="a"
            href="/tracking"
            sx={{ 
              borderRadius: '8px',
              margin: '4px',
              padding: '10px 20px',
              '&:hover': { 
                backgroundColor: '#fff4e6',
                color: '#f8842b'
              } 
            }}
          >
            {t('trackOrder')}
          </MenuItem>
          <MenuItem
            onClick={handleMenuClose}
            component="a"
            href="/feedbacks"
            sx={{ 
              borderRadius: '8px',
              margin: '4px', 
              padding: '10px 20px',
              '&:hover': { 
                backgroundColor: '#fff4e6',
                color: '#f8842b'
              } 
            }}
          >
            {t('client_feedbacks')}
          </MenuItem>
          <MenuItem
            onClick={handleMenuClose}
            component="a"
            href="https://price.aminejameli.com/contact"
            target="_blank"
            sx={{ 
              borderRadius: '8px',
              margin: '4px',
              padding: '10px 20px',
              '&:hover': { 
                backgroundColor: '#fff4e6',
                color: '#f8842b'
              } 
            }}
          >
            {t('contact_us')}
          </MenuItem>
          <MenuItem
            onClick={handleMenuClose}
            component="a"
            href="/meeting"
            sx={{ 
              borderRadius: '8px',
              margin: '4px',
              padding: '10px 20px',
              '&:hover': { 
                backgroundColor: '#fff4e6',
                color: '#f8842b'
              } 
            }}
          >
            {t('schedule_meeting_with_us')}
          </MenuItem>
          <MenuItem
            onClick={handleMenuClose}
            component="a"
            href="https://chat.cxgenie.ai?aid=4506fd38-76f9-4f14-9536-bbd43c76d75c&lang=ar"
            target="_blank" 
            sx={{ 
              borderRadius: '8px',
              margin: '4px',
              padding: '10px 20px',
              '&:hover': { 
                backgroundColor: '#fff4e6',
                color: '#f8842b'
              } 
            }}
          >
            {t('help_chatbot')}
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
