import React, { useEffect } from 'react';
import Header from '../NavBar';


const CalendlyInlineWidget = ({user}) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="container py-20">
      <div className="hero_area">
        <Header user={user} />
      </div>
      <div 
        className="calendly-inline-widget my-4 pt-4" 
        data-url="https://calendly.com/amine-jameli-services/30min?hide_gdpr_banner=1" 
        style={{ minWidth: '320px', height: '700px'  }} 
      />
    </div>
  ); 
}

export default CalendlyInlineWidget;