import React, { useState, useEffect } from 'react';
import { Send, LogIn, HomeIcon } from 'lucide-react';
import Lottie from 'lottie-react';
import styles from './FeedbackScreen.module.css';
import useFeedbacks from '../../../hooks/useFeedbacks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SEO from '../../../services/seo';
import loadingAnimation from '../../../assets/AJS_LOADING.json'; // Ensure this path is correct

const FeedbackScreen = ({ user }) => {
  const { feedbacks, loading, addFeedback, updateFeedback } = useFeedbacks(user?.uid);
  const { t } = useTranslation();
  const [newFeedback, setNewFeedback] = useState("");
  const [rating, setRating] = useState(0);
  const [editingFeedback, setEditingFeedback] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const existingFeedback = feedbacks.find(fb => fb.userId === user.uid);
      if (existingFeedback) {
        setNewFeedback(existingFeedback.message);
        setRating(existingFeedback.rating);
        setEditingFeedback(existingFeedback);
      } else {
        setEditingFeedback(null);
      }
    }
  }, [user, feedbacks]);

  const handleGoLogin = () => {
    navigate('/u?action=feedbacks');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newFeedback.trim() && rating > 0) {
      const feedbackData = {
        message: newFeedback,
        rating: rating,
        timestamp: new Date(),
        userId: user.uid,
      };
      try {
        if (editingFeedback) {
          await updateFeedback(editingFeedback.id, feedbackData);
        } else {
          await addFeedback(feedbackData);
        }
        setNewFeedback("");
        setRating(0);
        setEditingFeedback(null);
      } catch (error) {
        console.error("Error processing feedback:", error);
      }
    }
  };

  const renderStars = (rating) => {
    return [...Array(5)].map((_, i) => (
      <span key={i} className={i < rating ? styles.starFilled : styles.starEmpty}>★</span>
    ));
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <Lottie animationData={loadingAnimation} loop={true} className={styles.lottieAnimation} />
        <p>{t('view_feedbacks')}</p>
      </div>
    );
  }

  const calculateAverageRating = () => {
    if (feedbacks.length === 0) return 0;
    const sum = feedbacks.reduce((acc, feedback) => acc + feedback.rating, 0);
    return (sum / feedbacks.length).toFixed(1);
  };

  return (
    <>
      <SEO
        title={t('client_feedbacks')}
        description="Discover what our clients are saying about us"
        url="https://services.aminejameli.com/feedbacks"
        image="https://services.aminejameli.com/images/review.webp"
      />
      <div className={styles.container}>
        <div className={styles.card}>
          <div className={styles.homeRedirect}>
            <button onClick={() => navigate('/')} className={styles.homeButton}>
              <HomeIcon size={18} className={styles.homeIcon} />
            </button>
          </div>
          <h1 className={styles.title}>{t('client_feedbacks')}</h1>
          {user ? (
            <form onSubmit={handleSubmit} className={styles.feedbackForm}>
              <h2 className={styles.sectionTitle}>
                {editingFeedback ? t('edit_your_feedback') : t('share_your_thoughts')}
              </h2>
              <textarea
                value={newFeedback}
                onChange={(e) => setNewFeedback(e.target.value)}
                className={styles.textarea}
                rows="4"
                placeholder={t('enter_feedback')}
              ></textarea>
              <div className={styles.formFooter}>
                <div className={styles.starRating}>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <button
                      key={star}
                      type="button"
                      onClick={() => setRating(star)}
                      className={`${styles.starButton} ${star <= rating ? styles.starFilled : styles.starEmpty}`}
                    >
                      ★
                    </button>
                  ))}
                </div>
                <button type="submit" className={styles.submitButton}>
                  <Send size={18} className={styles.sendIcon} />
                  {editingFeedback ? t('update_feedback') : t('add_feedback')}
                </button>
              </div>
            </form>
          ) : (
            <div className={styles.notLoggedInContainer}>
              <h2 className={styles.sectionTitle}>{t('join_the_conversation')}</h2>
              <p className={styles.notLoggedInText}>{t('login_to_share_feedback')}</p>
              <button onClick={handleGoLogin} className={styles.loginButton}>
                <LogIn size={18} className={styles.loginIcon} />
                {t('login_to_give_feedback')}
              </button>
            </div>
          )}
          <div className={styles.feedbackListContainer}>
            <h2 className={styles.sectionTitle}>{t('client_feedbacks')}</h2>
            <div className={styles.feedbackStats}>
              <p className={styles.feedbackCount}>{feedbacks.length} {t('feedbacks')}</p>
              <div className={styles.averageRating}>
                <span className={styles.starFilled}>★</span>
                <span>{calculateAverageRating()}</span>
              </div>
            </div>
            <div className={styles.feedbackList}>
              {feedbacks.map((feedback) => (
                <div key={feedback.id} className={styles.feedbackItem}>
                  <p className={styles.feedbackText}>{feedback.message}</p>
                  <div className={styles.feedbackMeta}>
                    <div className={styles.starRating}>
                      {renderStars(feedback.rating)}
                    </div>
                    <p className={styles.userName}>{feedback.user?.fullName || t('anonymous')}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.socialLink}>
              <a
                href="https://www.instagram.com/stories/highlights/18002344562538976/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.instagramButton}
              >
                <i className="fa fa-instagram" aria-hidden="true"></i>
                <span>{t('view_more_on_instagram')}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedbackScreen;
